import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import { bindActionCreators } from "redux";
import $ from "jquery";
import Logo from "../../../Images/brand-log.png";
import { actionCreaters } from "../../../Redux";

export default function Header() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, user_type } = state;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const [Dark, setDark] = useState(localStorage.getItem("theme"));

  useEffect(() => {
    if (adminAccessToken) {
      if (Dark === "DarkTheme") {
        $("html").attr("class", "dark-theme");
      } else {
        $("html").attr("class", "light-theme");
      }
    }
  }, [Dark, adminAccessToken]);

  const setTheme = async () => {
    if (Dark !== "DarkTheme") {
      localStorage.setItem("theme", "DarkTheme");
      setDark("DarkTheme");
    } else {
      localStorage.setItem("theme", "LightTheme");
      setDark("LightTheme");
    }
  };

  const logOut = async () => {
    await userActions.logOut("adminLogOut");
    navigate("/admin/login", { replace: true });
  };

  const menuBtn = () => {
    $(".site-main-design").toggleClass("full-show");
    $(".site-footer-main").toggleClass("full-show");
  };

  return (
    <>
      <header className="top-header customer-header">
        <nav className="navbar navbar-expand gap-3">
          <button
            id="menu-open-close"
            onClick={menuBtn}
            data-bs-target="#sidebar"
            data-bs-toggle="collapse"
            className="border rounded-1 p-1 text-decoration-none"
          >
            <i className="bi bi-list bi-lg "></i>
          </button>
          <Link to="/admin/dashboard" className="custom-logo">
            <img src={Logo} alt="logo icon" />
          </Link>
          <div className="theme-changer">
            <input
              type="checkbox"
              className="checkbox"
              id="checkbox"
              checked={Dark === "DarkTheme" ? true : false}
              readOnly={true}
            />
            <label
              htmlFor="checkbox"
              className="checkbox-label"
              onClick={setTheme}
            >
              <i className="bi bi-moon-fill"></i>
              <i className="bi bi-brightness-high-fill"></i>
              <span className="ball"></span>
            </label>
          </div>

          <div className="top-navbar-right ms-auto">
            <ul className="navbar-nav align-items-center">
              <li className="nav-item dropdown dropdown-user-setting">
                <div className="user-changepass">
                  <div className="header-username">
                    Logged in as{" "}
                    <span className="text-capitalize">
                      {user_type ? user_type : ""}
                    </span>
                  </div>
                  <Link className="change-pass-btn " to="/admin/profile">
                    Edit Profile
                  </Link>

                  <button onClick={logOut} className="logout-btn">
                    Logout
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}
