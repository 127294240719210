import React, { useEffect, useState } from "react";
import MainHeading from "../includes/MainHeading";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminListService from "../../../services/admin-list.service";

import { useFormik } from "formik";
import { importDocument } from "../../../schema";
import { toast } from "react-toastify";

import ReactLoading from "react-loading";

export default function ImportCsv() {
  const [formSubmiting, setFormSubmiting] = useState(false);

  const [duplicate, setDuplicate] = useState([]);
  const [errorData, setErrorData] = useState([]);

  const [file, setFile] = useState("");

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();

  useEffect(() => {
    if (adminAccessToken) {
    } else {
      navigate("/admin/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  const handleFileChange = async (e) => {
    setFile(e.target.files[0]);
    setFieldValue("file", e.target.value);
  };

  const uploadSheet = async (values) => {
    setFormSubmiting(true);

    const formData = new FormData();
    if (file !== "") {
      formData.append("file", file);
    }

    formData.append("adminId", parseInt(admin_id));

    formData.append("email", values.email);

    try {
      const response = await AdminListService.uploadSheet(formData);

      toast.success(response?.data?.response, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setDuplicate([]);
      setFormSubmiting(false);
      navigate("/admin/vouchers");
    } catch (err) {
      console.log(err);
      setFormSubmiting(false);
      if (
        err?.response?.data?.failure?.length === 0 &&
        err?.response?.data?.duplicate?.duplicate?.length > 0
      ) {
        setErrorData([]);
        setDuplicate(err?.response?.data?.duplicate?.duplicate);
      } else {
        if (Array.isArray(err?.response?.data?.failure)) {
          const errors = [];

          err?.response?.data?.failure.map((value) => {
            return errors.push({
              row: value.row,
              errors: value.errors[0].toString().replaceAll("_", " "),
            });
          });

          setErrorData([...errors]);
          setDuplicate([]);
        }
      }
      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.error(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.data?.file?.length) {
        toast.error(err?.response?.data?.file[0], {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: { email: "no", file: "" },
    validationSchema: importDocument,
    onSubmit: (values) => {
      uploadSheet(values);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Import File" />
          <form className="row" onSubmit={handleSubmit} noValidate>
            <div className="mt-4 col-xl-12 col-lg-12 col-md-12 col-12">
              <div
                className={`card dashboard-cards ${
                  errors.file && touched.file ? "error-card" : ""
                }`}
              >
                {" "}
                {errors.file && touched.file ? (
                  <span className="text-danger">{errors.file}</span>
                ) : null}
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <h4 className="mb-0 col-lg-8">You Can Import XLSX File</h4>
                    <div className="col-lg-4 text-end">
                      <a
                        href="/assets/csv/Voucher_upload_sheet_sample.xlsx"
                        download={true}
                        className="btn btn-primary"
                      >
                        DOWNLOAD SAMPLE SHEET
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {errorData.length ? (
                    <div className="row">
                      {errorData.map((res, index) => {
                        return (
                          <div className="col-lg-6">
                            <div className="alert alert-danger " key={index}>
                              <b>Note!</b> Problem in row number {res.row} ,
                              <b> Error! </b>
                              {res.errors}
                            </div>{" "}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="SendeVoucher"
                        onChange={(e) => {
                          setFieldValue(
                            "email",
                            e.target.checked ? "yes" : "no"
                          );
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="SendeVoucher"
                      >
                        Send eVoucher
                      </label>
                    </div>
                  </div>
                  <div className=" file-area">
                    <input
                      type="file"
                      name="images"
                      id="images"
                      required="required"
                      accept=".xls, .xlsx, .csv"
                      onChange={handleFileChange}
                    />
                    <div className="file-dummy">
                      <div className="success">
                        Great, your files are selected. Keep on.
                      </div>
                      <div className="default">Import XLSX File</div>
                    </div>
                  </div>
                  {duplicate.length ? (
                    <div className="table-responsive mt-3">
                      <table className="text-nowrap  table caption-top">
                        <caption>
                          <strong className="font-17">
                            These certificates are not saved due to duplication.
                          </strong>
                        </caption>
                        <thead className="table-light">
                          <tr>
                            <th scope="col">Sr#</th>

                            <th scope="col">Certificate</th>
                          </tr>
                        </thead>
                        <tbody>
                          {duplicate.map((el, index) => (
                            <tr key={index}>
                              <td className="align-middle">{index + 1}</td>
                              <td className="align-middle">
                                <span className="text-control">{el}</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
                <div className="card-footer">
                  <div className="col-12 text-center">
                    <button className="btn btn-primary my-2 " type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
      <Footer />
      {formSubmiting && (
        <ReactLoading
          type="spinningBubbles"
          color="#ce202f"
          className="bar-loader"
        />
      )}
    </div>
  );
}
