import axios from "axios";

const API_URL = process.env.REACT_APP_API_Link;

const headers2 = {
  "Content-Type": "application/json",
};

const voucherCheck = (certificate) => {
  return axios.get(API_URL + `validate-voucher-syg/${certificate}`, {
    headers: headers2,
  });
};
const getCartCounter = (certificate) => {
  return axios.get(API_URL + `cart-counter-syg/${certificate}`, {
    headers: headers2,
  });
};

const getAllStates = () => {
  return axios.get(API_URL + `states`, {
    headers: headers2,
  });
};

const getProductsByVucher = (
  certificate,
  limit,
  page,
  values,
  direction,
  col
) => {
  return axios.get(
    API_URL +
      `product-by-voucher-syg/${certificate}?limit=${limit}&page=${page}${
        direction && col
          ? `&sort_column=${col}&sort_direction=${direction}`
          : ""
      }${
        values?.fieldtype && values?.searchval
          ? "&" + values.fieldtype + "=" + values.searchval
          : ""
      }${values?.gift ? "&gift_card=" + values.gift : ""}`,
    {
      headers: headers2,
    }
  );
};

const addInCart = (id, certificate) => {
  return axios.post(
    API_URL + `cart-syg/${certificate}`,
    { prod_id: id },
    {
      headers: headers2,
    }
  );
};

const getCartList = (certificate) => {
  return axios.get(API_URL + `cart-by-voucher-syg/${certificate}`, {
    headers: headers2,
  });
};

const checkCertiStatus = (certificate) => {
  return axios.get(API_URL + `voucher-status-syg/${certificate}`, {
    headers: headers2,
  });
};

const deletSingleCartItem = (id) => {
  return axios.delete(API_URL + `cart-syg/${id}`, {
    headers: headers2,
    data: {
      deleted_by: 1,
    },
  });
};
const deletCart = (certificate) => {
  return axios.delete(API_URL + `empty-cart-syg/${certificate}`, {
    headers: headers2,
  });
};

const putOrder = (values, certificate) => {
  return axios.post(
    API_URL + `submit-voucher-syg`,
    {
      certificate: certificate,
      fname: values.First,
      lname: values.Last,
      address: values.res_address,
      address2: values.address1,
      state: values.State,
      city: values.City,
      zip: values.Zip,
      phone: values.phone,
      email: values.email,
    },
    {
      headers: headers2,
    }
  );
};

const getOrderInfo = (certificate) => {
  return axios.get(API_URL + `order-detail-syg/${certificate}`, {
    headers: headers2,
  });
};

const contactUs = (values) => {
  return axios.post(
    API_URL + `contact-us-syg`,
    {
      phone: values.phone,
      email: values.email,
      name: values.name,
      subject: values.subject,
      description: values.description,
      question: values.comments,
    },
    {
      headers: headers2,
    }
  );
};

const getProductInfo = (id) => {
  return axios.get(API_URL + `product/${id}`, {
    headers: headers2,
  });
};

const voucherChecking = (certificate, product) => {
  return axios.get(API_URL + `verify-voucher/${certificate}/${product}`, {
    headers: headers2,
  });
};
const voucherRedeeming = (certificate, product) => {
  return axios.get(API_URL + `redeem-voucher/${certificate}/${product}`, {
    headers: headers2,
  });
};

const UserListService = {
  voucherCheck,
  getProductsByVucher,
  getCartCounter,
  addInCart,
  getCartList,
  checkCertiStatus,
  deletSingleCartItem,
  deletCart,
  getAllStates,
  putOrder,
  getOrderInfo,
  contactUs,
  getProductInfo,
  voucherChecking,
  voucherRedeeming,
};

export default UserListService;
